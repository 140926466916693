<template>
  <section>
    <router-view></router-view>
  </section>
</template>
<script>
  export default {
    name: 'configiracionRH',
    setup () {
      return {}
    }
  }
</script>
